@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./Roboto-Regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('./Roboto-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('./Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('./Roboto-Regular.woff') format('woff'), /* Modern Browsers */
	url('./Roboto-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('./Roboto-Regular.svg#Roboto') format('svg'); /* Legacy iOS */

}

@font-face {
	font-family: 'Roboto-Medium';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./Roboto-Medium.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('./Roboto-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('./Roboto-Medium.woff2') format('woff2'), /* Super Modern Browsers */
	url('./Roboto-Medium.woff') format('woff'), /* Modern Browsers */
	url('./Roboto-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
	url('./Roboto-Medium.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Roboto-Bold';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./Roboto-Bold.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('./Roboto-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('./Roboto-Bold.woff2') format('woff2'), /* Super Modern Browsers */
	url('./Roboto-Bold.woff') format('woff'), /* Modern Browsers */
	url('./Roboto-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
	url('./Roboto-Bold.svg#Roboto') format('svg'); /* Legacy iOS */
}